import { CraftAssetField, CraftLinkItField } from 'tsconfig/craft-types'
import Image from 'next/image'
import { AButton } from '../atoms/a-button'
import throttle from 'lodash/throttle'
import { useEffect, useRef } from 'react'

interface Step {
  stepTitle?: string
  stepContent?: string
  image?: Array<CraftAssetField>
}
export interface CStepsBlock {
  heading?: string
  subHeading?: string
  steps?: Step[]
  ctaSection?: Array<{
    sectionTitle?: string
    cta?: CraftLinkItField
  }>
}

interface CStepsProps {
  field: Array<CStepsBlock>
}

export const CSteps = ({ field }: CStepsProps) => {
  const { steps, heading, ctaSection, subHeading } = field?.[0] ?? []

  const componentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const isMobile = () => window.innerWidth <= 768

    const handleScroll = throttle(() => {
      if (!isMobile()) return

      const stickyCTA = document.querySelector('#sticky-cta') as HTMLElement
      if (!stickyCTA || !componentRef.current) return

      const componentRect = componentRef.current.getBoundingClientRect()
      const componentTopPosition = componentRect.top + window.pageYOffset
      const componentBottomPosition = componentRect.bottom
      const viewportHeight = window.innerHeight
      const topThreshold = 0
      const bottomThreshold = viewportHeight * 0.9

      const isTopInView =
        componentTopPosition <= window.pageYOffset + topThreshold
      const isBottomAboveThreshold = componentBottomPosition > bottomThreshold

      if (isTopInView && isBottomAboveThreshold) {
        stickyCTA.className =
          'fixed bg-gray-100 bottom-0 left-0 right-0 p-[24px]'
      } else {
        stickyCTA.className = 'static bg-white p-0 mt-[25px]'
      }
    }, 100)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="o-container o-container--2xl sm:px-20 md:flex flex-row gap-5 lg:gap-[100px] my-[80px]">
      <div ref={componentRef} className="flex-1">
        {heading && (
          <h3 className="text-4xl md:text-heading mb-[20px] text-gray-850">
            {heading}
          </h3>
        )}
        {subHeading && <h5 className="text-xl font-light">{subHeading}</h5>}
        {Boolean(steps?.length) && (
          <div className="flex flex-col gap-[50px] mt-[90px]">
            {steps?.map((step, i) => {
              return (
                <div
                  key={i}
                  className="flex flex-wrap items-center lg:items-start lg:flex-nowrap"
                >
                  <div className="lg:hidden flex-1 text-2xl uppercase font-semibold text-primary-dark-teal">
                    Step {i + 1}
                  </div>
                  {step.image?.[0]?.url && (
                    <Image
                      className="h-[70px] w-[70px] lg:mr-[30px]"
                      src={step.image?.[0]?.url}
                      alt={step.image?.[0]?.title ?? ''}
                      height={step.image?.[0]?.height}
                      width={step.image?.[0]?.width}
                    />
                  )}
                  <div>
                    <div className="text-xl font-semibold mb-[15px] mt-[19px] lg:mt-0 text-black">
                      <span className="hidden lg:inline-block mr-2.5">{`${
                        i + 1
                      }.`}</span>
                      {step.stepTitle}
                    </div>
                    {step.stepContent && (
                      <div
                        className="text-lg lg:pl-[30px] text-gray-850 [&>p>a]:underline"
                        dangerouslySetInnerHTML={{ __html: step.stepContent }}
                      />
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
      {Boolean(ctaSection?.length) && (
        <div id="sticky-cta">
          <div className="md:sticky md:top-[150px]">
            {ctaSection?.[0]?.sectionTitle && (
              <h4 className="text-3xl text-primary-dark-teal font-semibold text-center mb-[16px]">
                {ctaSection?.[0]?.sectionTitle}
              </h4>
            )}
            {ctaSection?.[0]?.cta?.url && (
              <AButton
                className="w-full lg:w-[350px]"
                type={ctaSection?.[0]?.cta?.type}
                href={ctaSection?.[0]?.cta?.url}
                rel={ctaSection?.[0]?.cta?.type === 'url' ? 'noreferrer' : ''}
                target={ctaSection?.[0]?.cta?.target}
                size="lg"
              >
                <span>{ctaSection?.[0]?.cta?.text}</span>
              </AButton>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export const CStepsFragment = `
  fragment steps on steps_MatrixField {
    ... on steps_block_BlockType {
      heading
      subHeading
      steps {
        ... on steps_BlockType {
          stepTitle
          stepContent
          image {
            url @transform(mode: "crop", width: 90, immediately: true)
            title
            width
            height
          }
        }
      }
      ctaSection {
        ... on ctaSection_BlockType {
          sectionTitle
          cta {
            type
            url
            text
            target
          }
        }
      }
    }
  }
`
